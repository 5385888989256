import Swiper from 'swiper'
import OnlineFairSwiper from '../swiper/OnlineFairSwiper'

window.addEventListener('DOMContentLoaded', () => {
  // 研修の現場特集カルーセル
  const specialSwiper = document.querySelectorAll('.m__carousel--special .special-swiper')
  const specialPrev = document.querySelectorAll('.m__carousel--special .swiper-button-prev')
  const specialNext = document.querySelectorAll('.m__carousel--special .swiper-button-next')
  const specialPage = document.querySelectorAll('.m__carousel--special .swiper-pagination')
  if (specialSwiper.length > 0) {
    for (let i = 0; i < specialSwiper.length; i++) {
      specialSwiper[i].className += i
      specialPrev[i].className += i
      specialNext[i].className += i
      specialPage[i].className += i
      const sliderCount = specialSwiper[i].children[0].children.length
      let loopSetting = true
      let effectType = 'fade'
      if (sliderCount > 1) {
        loopSetting = true
      } else {
        loopSetting = false
      }
      if (window.innerWidth < 768) {
        effectType = 'slide'
      }
      const swiper = new Swiper ('.special-swiper' + i, {
        'loop': loopSetting,
        'slidesPerView': 1,
        'spaceBetween': 20,
        'effect': effectType,
        'centeredSlides': true,
        'autoplay': {
          delay: 5000,
        },
        'pagination': {
          'el': '.m__carousel--special .js-swiper-paging' + i,
          'type': 'bullets',
          'clickable': true
        },
        'navigation': {
          'nextEl': '.m__carousel--special .js-swiper-next' + i,
          'prevEl': '.m__carousel--special .js-swiper-prev' + i
        },
        'watchOverflow': true,
        'breakpoints': {
          '768': {
            'slidesPerView': 3,
            'spaceBetween': 10
          }
        },
      })

    }
  }

  // イベントカルーセル
  const eventSwiper = document.querySelectorAll('.m__carousel--event .event-swiper')
  const eventPrev = document.querySelectorAll('.m__carousel--event .swiper-button-prev')
  const eventNext = document.querySelectorAll('.m__carousel--event .swiper-button-next')
  const eventPage = document.querySelectorAll('.m__carousel--event .swiper-pagination')
  if (eventSwiper.length > 0) {
    for (let i = 0; i < eventSwiper.length; i++) {
      eventSwiper[i].className += i
      eventPrev[i].className += i
      eventNext[i].className += i
      eventPage[i].className += i
      const sliderCount = eventSwiper[i].children[0].children.length
      let loopSetting = true
      let effectType = 'fade'
      if (sliderCount > 1) {
        loopSetting = true
      } else {
        loopSetting = false
      }
      if (window.innerWidth < 768) {
        effectType = 'slide'
      }
      const swiper = new Swiper ('.event-swiper' + i, {
        'loop': loopSetting,
        'slidesPerView': 1,
        'spaceBetween': 20,
        'effect': effectType,
        'centeredSlides': true,
        'autoplay': {
          delay: 5000,
        },
        'pagination': {
          'el': '.m__carousel--event .js-swiper-paging' + i,
          'type': 'bullets',
          'clickable': true
        },
        'navigation': {
          'nextEl': '.m__carousel--event .js-swiper-next' + i,
          'prevEl': '.m__carousel--event .js-swiper-prev' + i
        },
        'watchOverflow': true,
        'breakpoints': {
          '768': {
            'slidesPerView': 3,
            'spaceBetween': 10
          }
        },
      })

    }
  }

  // レジナビのイチオシカルーセル
  const recommendSwiper = document.querySelectorAll('.m__carousel--recommend .recommend-swiper')
  const recommendPrev = document.querySelectorAll('.m__carousel--recommend .swiper-button-prev')
  const recommendNext = document.querySelectorAll('.m__carousel--recommend .swiper-button-next')
  const recommendPage = document.querySelectorAll('.m__carousel--recommend  .swiper-pagination')
  if (recommendSwiper.length > 0) {
    for (let i = 0; i < recommendSwiper.length; i++) {
      recommendSwiper[i].className += i
      recommendPrev[i].className += i
      recommendNext[i].className += i
      recommendPage[i].className += i
      const sliderCount = recommendSwiper[i].children[0].children.length
      let loopSetting = true
      if (sliderCount > 1) {
        loopSetting = true
      } else {
        loopSetting = false
      }
      const swiper = new Swiper ('.recommend-swiper' + i, {
        'loop': loopSetting,
        'slidesPerView': 1,
        'spaceBetween': 0,
        'autoplay': {
          delay: 5000,
        },
        'pagination': {
          'el': '.m__carousel--recommend .js-swiper-paging' + i,
          'type': 'bullets',
          'clickable': true
        },
        'navigation': {
          'nextEl': '.m__carousel--recommend .js-swiper-next' + i,
          'prevEl': '.m__carousel--recommend .js-swiper-prev' + i
        },
        'watchOverflow': true,
        'breakpoints': {
          '768': {
            'slidesPerView': 3,
            'centeredSlides': true,
            'spaceBetween': 15
          }
        },
      })
    }
  }
  // レジナビのイチオシカルーセル 最大幅
  const recommendSwiper02 = document.querySelectorAll('.m__carousel--recommend02 .recommend-swiper02')
  const recommendPrev02 = document.querySelectorAll('.m__carousel--recommend02 .swiper-button-prev')
  const recommendNext02 = document.querySelectorAll('.m__carousel--recommend02 .swiper-button-next')
  const recommendPage02 = document.querySelectorAll('.m__carousel--recommend02  .swiper-pagination')
  if (recommendSwiper02.length > 0) {
    for (let i = 0; i < recommendSwiper02.length; i++) {
      recommendSwiper02[i].className += i
      recommendPrev02[i].className += i
      recommendNext02[i].className += i
      recommendPage02[i].className += i
      const sliderCount = recommendSwiper02[i].children[0].children.length
      let loopSetting = true
      if (sliderCount > 1) {
        loopSetting = true
      } else {
        loopSetting = false
      }
      const swiper = new Swiper ('.recommend-swiper02' + i, {
        'loop': loopSetting,
        'slidesPerView': 3,
        'spaceBetween': 30,
        'autoplay': {
          delay: 5000,
        },
        'pagination': {
          'el': '.m__carousel--recommend02 .js-swiper-paging' + i,
          'type': 'bullets',
          'clickable': true
        },
        'navigation': {
          'nextEl': '.m__carousel--recommend02 .js-swiper-next' + i,
          'prevEl': '.m__carousel--recommend02 .js-swiper-prev' + i
        },
        'watchOverflow': true,
        'breakpoints': {
          '768': {
            'slidesPerView': 3,
            'centeredSlides': true,
            'spaceBetween': 15
          }
        },
      })
    }
  }
  // バナーカルーセル 最大幅
  const bannerSwiper = document.querySelectorAll('.m__carousel--banner01 .banner-swiper')
  const bannerPage = document.querySelectorAll('.m__carousel--banner01  .swiper-pagination')
  const bannerPrev = document.querySelectorAll('.m__carousel--banner01 .swiper-button-prev')
  const bannerNext = document.querySelectorAll('.m__carousel--banner01 .swiper-button-next')
  if (bannerSwiper.length > 0) {
    for (let i = 0; i < bannerSwiper.length; i++) {
      bannerSwiper[i].className += i
      bannerPage[i].className += i
      bannerPrev[i].className += i
      bannerNext[i].className += i
      const sliderCount = bannerSwiper[i].children[0].children.length
      let loopSetting = true
      if (sliderCount > 3) {
        loopSetting = true
      } else {
        loopSetting = false
      }
      const swiper = new Swiper ('.banner-swiper' + i, {
        'loop': loopSetting,
        'slidesPerView': 3,
        'spaceBetween': 20,
        'autoplay': {
          delay: 5000,
        },
        'pagination': {
          'el': '.m__carousel--banner01 .js-swiper-paging' + i,
          'type': 'bullets',
          'clickable': true
        },
        'navigation': {
          'nextEl': '.m__carousel--banner01 .js-swiper-next' + i,
          'prevEl': '.m__carousel--banner01 .js-swiper-prev' + i
        },
        'watchOverflow': true,
        'breakpoints': {
          '768': {
            'slidesPerView': 1,
            'centeredSlides': true,
            'spaceBetween': 10
          }
        }
      })
    }
  }

      // バナーカルーセル 最大幅
  const bannerSwiper02 = document.querySelectorAll('.m__carousel--banner02 .banner-swiper02')
  const bannerPage02 = document.querySelectorAll('.m__carousel--banner02  .swiper-pagination')
  const bannerPrev02 = document.querySelectorAll('.m__carousel--banner02 .swiper-button-prev')
  const bannerNext02 = document.querySelectorAll('.m__carousel--banner02 .swiper-button-next')
  if (bannerSwiper02.length > 0) {
    for (let i = 0; i < bannerSwiper02.length; i++) {
      bannerSwiper02[i].className += i
      bannerPage02[i].className += i
      bannerPrev02[i].className += i
      bannerNext02[i].className += i
      const sliderCount = bannerSwiper02[i].children[0].children.length
      let loopSetting = true
      if (sliderCount > 3) {
        loopSetting = true
      } else {
        loopSetting = false
      }
      const swiper = new Swiper ('.banner-swiper02' + i, {
        'loop': loopSetting,
        'slidesPerView': 3,
        'spaceBetween': 20,
        'autoplay': {
          delay: 5000,
        },
        'pagination': {
          'el': '.m__carousel--banner02 .js-swiper-paging' + i,
          'type': 'bullets',
          'clickable': true
        },
        'navigation': {
          'nextEl': '.m__carousel--banner02 .js-swiper-next' + i,
          'prevEl': '.m__carousel--banner02 .js-swiper-prev' + i
        },
        'watchOverflow': true,
        'breakpoints': {
          '768': {
            'slidesPerView': 3,
            'centeredSlides': true,
            'spaceBetween': 15
          }
        }
      })
    }
  }

  // 新着、人気動画カルーセル
  const movieSwiper = document.querySelectorAll('.m__common-list__movie .movie-swiper')
  const moviePrev = document.querySelectorAll('.m__common-list__movie .swiper-button-prev')
  const movieNext = document.querySelectorAll('.m__common-list__movie .swiper-button-next')
  const moviePage = document.querySelectorAll('.m__common-list__movie .swiper-pagination')
  if (movieSwiper.length > 0 && window.innerWidth < 768) {
    for (let i = 0; i < movieSwiper.length; i++) {
      movieSwiper[i].className += i
      moviePrev[i].className += i
      movieNext[i].className += i
      moviePage[i].className += i
      const sliderCount = movieSwiper[i].children[0].children.length
      let loopSetting = true
      if (sliderCount > 1) {
        loopSetting = true
      } else {
        loopSetting = false
      }
      const swiper = new Swiper ('.movie-swiper' + i, {
        'loop': loopSetting,
        'slidesPerView': 1,
        'spaceBetween': 15,
        'autoplay': {
          delay: 5000,
        },
        'pagination': {
          'el': '.m__common-list__movie .js-swiper-paging' + i,
          'type': 'bullets',
          'clickable': true
        },
        'navigation': {
          'nextEl': '.m__common-list__movie .js-swiper-next' + i,
          'prevEl': '.m__common-list__movie .js-swiper-prev' + i
        },
        'watchOverflow': true,
      })

    }
  }

  // 検索下バナーカルーセル
  const topBannerSwiper = document.querySelectorAll('.m__carousel__top-banner .top-banner-swiper')
  const topBannerPrev = document.querySelectorAll('.m__carousel__top-banner .swiper-button-prev')
  const topBannerNext = document.querySelectorAll('.m__carousel__top-banner .swiper-button-next')
  const topBannerPage = document.querySelectorAll('.m__carousel__top-banner .swiper-pagination')
  if (topBannerSwiper.length > 0) {
    for (let i = 0; i < topBannerSwiper.length; i++) {
      topBannerSwiper[i].className += i
      topBannerPrev[i].className += i
      topBannerNext[i].className += i
      topBannerPage[i].className += i
      const sliderCount = topBannerSwiper[i].children[0].children.length
      let loopSetting = true
      if (sliderCount > 2) {
        loopSetting = true
      } else {
        loopSetting = false
      }
      const swiper = new Swiper ('.top-banner-swiper' + i, {
        'loop': loopSetting,
        'slidesPerView': 2,
        'spaceBetween': 15,
        'autoplay': {
          delay: 5000,
        },
        'pagination': {
          'el': '.m__carousel__top-banner .js-swiper-paging' + i,
          'type': 'bullets',
          'clickable': true
        },
        'navigation': {
          'nextEl': '.m__carousel__top-banner .js-swiper-next' + i,
          'prevEl': '.m__carousel__top-banner .js-swiper-prev' + i
        },
        'watchOverflow': true,
        'breakpoints': {
          '768': {
            'slidesPerView': 3,
            'centeredSlides': true,
            'spaceBetween': 10
          }
        }
      })

    }
  }

  //レジナビFairOnlineバナーエリア
  const onlineFairSwiper = new OnlineFairSwiper(
    '.m__carousel--top-fair',
    '.top-fair-swiper',
    '.swiper-pagination',
    '.swiper-button-prev',
    '.swiper-button-next'
  )
  onlineFairSwiper.execute()
}, false)
