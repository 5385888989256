import Swiper from 'swiper'

export default class OnlineFairSwiper {
  constructor(carouselClass, swiperClass, pageClass, prevClass, nextClass) {
    this.carouselClass = carouselClass
    this.swiperClass = swiperClass
    this.pageClass = pageClass
    this.prevClass = prevClass
    this.nextClass = nextClass
  }

  execute() {
    const fairSwiper = document.querySelectorAll(this.carouselClass + ' ' + this.swiperClass)
    const fairPage = document.querySelectorAll(this.carouselClass + ' ' + this.pageClass)
    const fairPrev = document.querySelectorAll(this.carouselClass + ' ' + this.prevClass)
    const fairNext = document.querySelectorAll(this.carouselClass + ' ' + this.nextClass)
    if (fairSwiper.length > 0) {
      for (let i = 0; i < fairSwiper.length; i++) {
        fairSwiper[i].className += i
        fairPage[i].className += i
        fairPrev[i].className += i
        fairNext[i].className += i
        const sliderCount = fairSwiper[i].children[0].children.length
        let loopSetting = true
        if (sliderCount > 1) {
          loopSetting = true
        } else {
          loopSetting = false
        }
        const swiper = new Swiper (this.swiperClass + i, {
          'loop': loopSetting,
          'slidesPerView': 1,
          'spaceBetween': 20,
          'autoplay': {
            delay: 5000,
          },
          'pagination': {
            'el': this.carouselClass + ' ' + '.js-swiper-paging' + i,
            'type': 'bullets',
            'clickable': true
          },
          'navigation': {
            'nextEl': this.carouselClass + ' ' + '.js-swiper-next' + i,
            'prevEl': this.carouselClass + ' ' + '.js-swiper-prev' + i
          },
          'watchOverflow': true,
          'observer': true,
          'observeParents': true
        })
      }
    }
  }
}
